import {
  HeroSection,
  HeroSectionProps,
  useCaseType,
} from '@rimactechnology/rimac-ui-lib';

import { HeroFragment } from '@/api';

interface HeroProps {
  hero?: HeroFragment | null;
  titleMaxWidth?: number;
  subtitleMaxWidth?: number;
}

export const Hero = ({ hero, titleMaxWidth, subtitleMaxWidth }: HeroProps) => {
  const heroProps: HeroSectionProps = {
    titleMaxWidth,
    subtitleMaxWidth,
    useCase: 'group' as useCaseType,
    enabledLazyLoading: true,
    heroImage: {
      image: {
        alt: hero?.image?.alt || '',
        blurHash:
          hero?.image?.img_desktop.data?.attributes?.blurhash ||
          'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
        imgSrc: hero?.image?.img_desktop.data?.attributes?.url || '',
        imgSrcMobile: hero?.image?.img_mobile?.data?.attributes?.url || '',
      },
    },
    title: hero?.title || '',
    button: hero?.button
      ? {
          label: hero?.button.label,
          path: hero?.button.path,
          useCase: 'group',
        }
      : undefined,
  };

  return <HeroSection {...heroProps} />;
};
