import { projectConfig } from '../../constants/projectConfig';

const getCdlPublicId = (url?: string) => {
  let extractedPath = '';

  if (url === undefined) {
    return extractedPath;
  }

  const escapedProjectName = projectConfig.cloudinaryProjectName.replace(
    /[-\/\\^$*+?.()|[\]{}]/g,
    '\\$&',
  );
  const regexString = `(${escapedProjectName}/[\\w\\/-]+)\\.\\w+$`;
  const match = url.match(new RegExp(regexString));
  if (match) {
    extractedPath = match[1];
  }

  return extractedPath;
};

export default getCdlPublicId;
