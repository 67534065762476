import { InferGetStaticPropsType, NextPage } from 'next';

import {
  FooterDocument,
  FooterQuery,
  FooterQueryVariables,
  HomeHeadlineWithImageDocument,
  HomeHeadlineWithImageQuery,
  HomeHeadlineWithImageQueryVariables,
  HomeHeroDocument,
  HomeHeroQuery,
  HomeHeroQueryVariables,
  HomeLargeImageSectionDocument,
  HomeLargeImageSectionQuery,
  HomeLargeImageSectionQueryVariables,
  HomeMapSectionDocument,
  HomeMapSectionQuery,
  HomeMapSectionQueryVariables,
  HomeNewsroomDocument,
  HomeNewsroomQuery,
  HomeNewsroomQueryVariables,
  HomeSeoDocument,
  HomeSeoQuery,
  HomeSeoQueryVariables,
  HomeSliderImageSectionDocument,
  HomeSliderImageSectionQuery,
  HomeSliderImageSectionQueryVariables,
  HomeTextBlockDocument,
  HomeTextBlockQuery,
  HomeTextBlockQueryVariables,
  HomeValuesDocument,
  HomeValuesQuery,
  HomeValuesQueryVariables,
  NavigationDocument,
  NavigationQuery,
  NavigationQueryVariables,
} from '@/api';
import { addApolloState, initializeApollo } from '@/api/apolloClient';
import { Homepage } from '@/views/home';

export const getStaticProps = async () => {
  const client = initializeApollo();

  // prefetch
  await client.query({ query: HomeHeroDocument });
  await client.query({ query: HomeHeadlineWithImageDocument });
  await client.query({ query: HomeTextBlockDocument });
  await client.query({ query: HomeSliderImageSectionDocument });
  await client.query({ query: HomeValuesDocument });
  await client.query({ query: HomeMapSectionDocument });
  await client.query({ query: HomeLargeImageSectionDocument });
  await client.query({ query: HomeNewsroomDocument });
  await client.query({ query: HomeSeoDocument });
  await client.query({ query: NavigationDocument });
  await client.query({ query: FooterDocument });

  const { data: heroData } = await client.query<
    HomeHeroQuery,
    HomeHeroQueryVariables
  >({
    query: HomeHeroDocument,
  });

  const { data: headlineWithImageSectionData } = await client.query<
    HomeHeadlineWithImageQuery,
    HomeHeadlineWithImageQueryVariables
  >({
    query: HomeHeadlineWithImageDocument,
  });

  const { data: textBlockData } = await client.query<
    HomeTextBlockQuery,
    HomeTextBlockQueryVariables
  >({ query: HomeTextBlockDocument });

  const { data: sliderImaseSectionData } = await client.query<
    HomeSliderImageSectionQuery,
    HomeSliderImageSectionQueryVariables
  >({ query: HomeSliderImageSectionDocument });

  const { data: valuesData } = await client.query<
    HomeValuesQuery,
    HomeValuesQueryVariables
  >({ query: HomeValuesDocument });

  const { data: mapSectionData } = await client.query<
    HomeMapSectionQuery,
    HomeMapSectionQueryVariables
  >({ query: HomeMapSectionDocument });

  const { data: largeImageSectionData } = await client.query<
    HomeLargeImageSectionQuery,
    HomeLargeImageSectionQueryVariables
  >({ query: HomeLargeImageSectionDocument });

  const { data: newsroomData } = await client.query<
    HomeNewsroomQuery,
    HomeNewsroomQueryVariables
  >({
    query: HomeNewsroomDocument,
  });

  const { data: seoData } = await client.query<
    HomeSeoQuery,
    HomeSeoQueryVariables
  >({ query: HomeSeoDocument });

  const { data: headerData } = await client.query<
    NavigationQuery,
    NavigationQueryVariables
  >({
    query: NavigationDocument,
  });

  const { data: footerData } = await client.query<
    FooterQuery,
    FooterQueryVariables
  >({
    query: FooterDocument,
  });

  return addApolloState(client, {
    props: {
      heroData,
      headlineWithImageSectionData,
      textBlockData,
      sliderImaseSectionData,
      valuesData,
      mapSectionData,
      largeImageSectionData,
      newsroomData,
      seoData,
      headerData,
      footerData,
    },
  });
};

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  heroData,
  headlineWithImageSectionData,
  textBlockData,
  sliderImaseSectionData,
  valuesData,
  mapSectionData,
  largeImageSectionData,
  newsroomData,
  seoData,
  headerData,
  footerData,
}) => (
  <Homepage
    seo={seoData?.home?.data?.attributes?.seo}
    hero={heroData?.home?.data?.attributes?.hero}
    headlineWithImageSection={
      headlineWithImageSectionData?.home?.data?.attributes
        ?.headlineWithImageSection
    }
    textBlock={textBlockData?.home?.data?.attributes?.textBlock}
    sliderImageSection={
      sliderImaseSectionData?.home?.data?.attributes?.sliderImageSection
    }
    values={valuesData?.home?.data?.attributes?.values}
    mapSection={mapSectionData?.home?.data?.attributes?.mapSection}
    largeImageSection={
      largeImageSectionData?.home?.data?.attributes?.largeImageSection
    }
    newsroom={newsroomData?.home?.data?.attributes?.newsroom}
    header={headerData}
    footer={footerData}
  />
);

export default Home;
