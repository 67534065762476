import {
  HeadlineWithImageSection,
  HeadlineWithImageSectionProps,
} from '@rimactechnology/rimac-ui-lib';

import { HeadlineWithImageSectionFragment } from '@/api';
import getCdlPublicId from '@/hooks/useCdlPublicId';

interface HeadlineWithImageProps {
  headlineWithImageSection?: HeadlineWithImageSectionFragment | null;
}

export default function HeadlineWithImage({
  headlineWithImageSection,
}: HeadlineWithImageProps) {
  const headlineWithImageSectionProps: HeadlineWithImageSectionProps = {
    anchor: headlineWithImageSection?.anchor || undefined,
    image: {
      imgSrc: getCdlPublicId(
        headlineWithImageSection?.image?.img_desktop.data?.attributes?.url ||
          '',
      ),
      imgSrcMobile: getCdlPublicId(
        headlineWithImageSection?.image?.img_mobile?.data?.attributes?.url ||
          undefined,
      ),
      alt: headlineWithImageSection?.image?.alt || '',
      blurHash:
        headlineWithImageSection?.image?.img_desktop?.data?.attributes
          ?.blurhash || 'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
    },
    title: headlineWithImageSection?.title || '',
    text: headlineWithImageSection?.text || '',
    buttonLink: {
      label: headlineWithImageSection?.button?.label || '',
      path: headlineWithImageSection?.button?.path || '',
    },
    tableTitle: headlineWithImageSection?.mobileTableTitle || '',
    table: (headlineWithImageSection?.mobileTable || []).map((option) => ({
      label: option?.label || '',
      value: option?.value || '',
    })),
  };

  return <HeadlineWithImageSection {...headlineWithImageSectionProps} />;
}
