interface ProjectConfig {
  cloudinaryProjectName: string;
  projectVideoCompanyLink: string;
}

const projectConfig: ProjectConfig = {
  cloudinaryProjectName: 'rimac/rimac-group',
  projectVideoCompanyLink: '/group',
};

const projectVideoLogoPublicId = `${projectConfig.cloudinaryProjectName}/global/logo-video`;

export { projectConfig, projectVideoLogoPublicId };
