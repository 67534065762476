import {
  headingTagType,
  Newsroom,
  NewsroomProps,
  useCaseType,
} from '@rimactechnology/rimac-ui-lib';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import { NewsroomFragment } from '@/api';
import { initializeArticleApollo } from '@/api/apolloClient';
import getCdlPublicId from '@/hooks/useCdlPublicId';

import { GET_ARTICLES } from './query';

interface Article {
  id: string;
  title: string;
  slug: string;
  _createdAt: string;
  headerImage: {
    id: string;
    width: number;
    url: string;
    title: string | null;
    mimeType: string;
    responsiveImage: {
      webpSrcSet: string;
      width: number;
      src: string;
      aspectRatio: string;
      alt: string | null;
      height: string;
    };
  };
  topics: { slug: string; title: string }[];
}

interface NewsroomSectionProps {
  strapiData?: NewsroomFragment | null;
}

export default function NewsroomSection({ strapiData }: NewsroomSectionProps) {
  const [articles, setArticles] = useState<Article[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const articleClient = initializeArticleApollo();
        const { data } = await articleClient.query({
          query: GET_ARTICLES,
        });

        setArticles(data.allPressReleases);
      } catch (err) {
        setError('Failed to fetch articles');
      }
    };

    fetchArticles();
  }, []);

  const newsroomProps: NewsroomProps = {
    useCase: 'group' as useCaseType,
    heading: strapiData?.title || undefined,
    anchor: strapiData?.anchor || undefined,
    button: strapiData?.button?.label
      ? {
          path: strapiData?.button?.path || '',
          label: strapiData?.button?.label || '',
          openInNewTab: !!strapiData?.button?.openInNewTab,
        }
      : undefined,
    articles: (articles || []).map((article, index) => ({
      title: article.title || '',
      date: article._createdAt || '',
      tagName: article.topics[0].title as headingTagType,
      // TODO:
      tagType: index === 0 ? 'light' : index === 1 ? 'default' : 'dark',
      link: `https://www.rimac-newsroom.com/press-releases/${article.topics[0].slug}/${article.slug}`,
      image: {
        src: article.headerImage.url,
        alt:
          article.headerImage.responsiveImage.alt || article.headerImage.title,
        webpSrcSet: article.headerImage.responsiveImage.webpSrcSet,
      },
      imageBlock: (
        <Image
          src={article.headerImage.url}
          alt={
            article.headerImage.responsiveImage.alt ||
            article.headerImage.title ||
            'Alt for image'
          }
          width={880}
          height={520}
          className="group-hover:lib-scale-105 h-[260px] w-full object-cover transition-all duration-300"
        />
      ),
    })),
    cards: (strapiData?.cards || []).map((card) => ({
      title: card?.title || '',
      href: card?.link || '',
      openInNewTab: !!card?.openInNewTab,
      image: {
        imgSrc: getCdlPublicId(
          card?.image?.img_desktop.data?.attributes?.url || '',
        ),
        imgSrcMobile: getCdlPublicId(
          card?.image?.img_mobile?.data?.attributes?.url || undefined,
        ),
        alt: card?.image?.alt || '',
        blurHash:
          card?.image?.img_desktop.data?.attributes?.blurhash ||
          'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
      },
    })),
  };

  if (error) return null;

  return <Newsroom {...newsroomProps} />;
}
