import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
  query {
    allPressReleases(first: 3, skip: 0, orderBy: _publishedAt_DESC) {
      id
      title
      slug
      _createdAt
      headerImage {
        id
        width
        url
        title
        mimeType
        responsiveImage {
          webpSrcSet
          width
          src
          aspectRatio
          alt
          height
        }
      }
      topics {
        title
        slug
      }
    }
  }
`;
